import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-grid-system";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchDataFromAPI, postDataToAPI } from "../shared/Shared";
import NtarasiIcon from "../assets/graphics/Untitled-1.png";
import "./animationStyles/CandyCrushToast.css";
import { motion } from "framer-motion";
import {
  endLevel10Gifts,
  endLevel11Gifts,
  endLevel12Gifts,
  endLevel13Gifts,
  endLevel14Gifts,
  endLevel15Gifts,
  endLevel1Gifts,
  endLevel2Gifts,
  endLevel3Gifts,
  endLevel4Gifts,
  endLevel5Gifts,
  endLevel6Gifts,
  endLevel7Gifts,
  endLevel8Gifts,
  endLevel9Gifts,
} from "../shared/GiftsList";

import b2 from "../assets/backgrounds/b2.jpg";
import b3 from "../assets/backgrounds/b3.jpg";
import b4 from "../assets/backgrounds/b4.jpg";
import b5 from "../assets/backgrounds/b5.jpg";
import b6 from "../assets/backgrounds/b6.jpg";
import b7 from "../assets/backgrounds/b7.jpg";
import b8 from "../assets/backgrounds/b8.jpg";
import b9 from "../assets/backgrounds/b9.jpg";
import b10 from "../assets/backgrounds/b10.jpg";
import b11 from "../assets/backgrounds/b11.jpg";
import b12 from "../assets/backgrounds/b12.jpg";
import b13 from "../assets/backgrounds/b13.jpg";
import b14 from "../assets/backgrounds/b14.jpg";
import b15 from "../assets/backgrounds/b15.jpg";
import defaultPic from "../assets/backgrounds/default.jpg";
import {
  initializeReactGA,
  startTimer,
  trackTimeSpent,
} from "../shared/analyticsUtils";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function PlayGround() {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    selectedGameMode,
    inviteCode,
    playerNumber,
    playerTwoAvator,
    playerTwoName,
    playerTwoGender,
    playerOneAvator,
    playerOneName,
    playerOneGender,
    uid,
  } = location.state;
  localStorage.setItem("inviteCode", inviteCode);

  const [cards, setCards] = useState([]);
  const [viewedCards, setViewedCards] = useState(() => {
    const storedViewedCards = localStorage.getItem("viewedCards");
    return storedViewedCards ? JSON.parse(storedViewedCards) : [];
  });
  const [gifts, setGifts] = useState(() => {
    const storedGifts = localStorage.getItem("gifts");
    return storedGifts ? JSON.parse(storedGifts) : [];
  });
  const [cardsCount, setCardsCount] = useState(1);
  const [questionid, setQuestionid] = useState([]);
  const [turn, setTurn] = useState(1);
  const [typedText, setTypedText] = useState("");
  const [myTurn, setMyTurn] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [messageFeedback, setMessageFeedback] = useState([]);
  const [currentMessageNumber, setCurrentMessageNumber] = useState();
  const [gameLevel, setGameLevel] = useState(1);
  const [backgroundPhoto, setBackgroundPhoto] = useState(defaultPic);
  const [categoryChanged, setCatgoryChanged] = useState(null);
  const [gameCategoryName, setGameCategoryName] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const chatContainerRef = useRef(null);

  const refreshPage = () => {
    window.location.reload(false);
  };

  const clearCategoryData = () => {
    // Set state values to empty arrays
    setViewedCards([]);
    setGifts([]);
    setCards([]);

    // Set localStorage values to empty arrays
    localStorage.setItem("viewedCards", JSON.stringify([]));
    localStorage.setItem("gifts", JSON.stringify([]));

    // Optionally, set other state values if needed
    setGameLevel(1);
    setCurrentMessageNumber(1);
    setMessageFeedback([]);
  };

  //Send To Google Analytics
  useEffect(() => {
    initializeReactGA();
    startTimer();

    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        trackTimeSpent();
      } else {
        startTimer();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      trackTimeSpent(); // Track time when component unmounts
    };
  }, []);

  const handlePlayClick = async (uid) => {
    //update status -> to be called in function after user palyed
    console.log("UId", uid);
    if (selectedGameMode === "online") {
      const formData = new FormData();
      formData.append("local_player", playerNumber);
      formData.append("sessionid", inviteCode);
      formData.append("current_player_pos", "0");
      formData.append("die_number", "0");
      if (uid !== null) {
        formData.append("current_card", 0);
      } else {
        formData.append("current_card", currentMessageNumber + 1);
      }
      formData.append("current_player_points", "0");
      formData.append("bg", "bg.png");
      formData.append("level", "0");
      formData.append("music", "default");
      formData.append("gonext", turn);
      formData.append("deck", uid);

      try {
        const response = await postDataToAPI(
          "/n_play_status_update.php",
          formData
        );
        console.log("UpdateRes" + response);
        if (response) {
          const newCard = cards[currentMessageNumber + 1];
          if (newCard && currentMessageNumber !== cards.length) {
            setViewedCards((viewedCards) => [...viewedCards, newCard]);
            setCardsCount(currentMessageNumber + 1);
          }
        }
      } catch (error) {
        console.log("error", error);
        // Handle error
      }
    } else {
      const newCard = cards[cardsCount];
      if (newCard && cardsCount !== cards.length) {
        setViewedCards((viewedCards) => [...viewedCards, newCard]);
        setCurrentMessageNumber(cardsCount);
        setCardsCount(cardsCount + 1);
      }
    }
  };

  //Display An alert after every game level is done
  const endOfLevelAlert = (level) => {
    const nextLevel = level + 1;
    let theText =
      "Congratulations! You've completed level " +
      level +
      ". Enjoy the gift box as you proceed to level " +
      nextLevel;
    Swal.fire({
      title: "Congratulations!",
      text: theText,
      iconHtml: '<span style="font-size: 24px;">🎉</span>',
      showCancelButton: false,
      confirmButtonColor: "green",
      confirmButtonStyle: {
        color: "white",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        handlePlayClick(null);
      }
    });
  };

  const displayToast = (toastMessage) => {
    toast(
      <motion.div
        initial={{ scale: 0, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.5, opacity: 0 }}
        transition={{ duration: 0.5, ease: "easeInOut" }}
        style={{
          background: "linear-gradient(45deg, #ff6ec7, #ffc371)",
          color: "white",
          borderRadius: "10px",
          padding: "20px",
          textAlign: "center",
          boxShadow: "0 4px 15px rgba(0, 0, 0, 0.2)",
        }}
      >
        <div style={{ fontSize: "2rem", marginBottom: "10px" }}>❤️</div>
        <div style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
          {toastMessage}
        </div>
      </motion.div>,
      {
        autoClose: 5000,
        position: "top-center",
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
      }
    );
    // toast(toastMessage, {
    //   className: "candy-toast",
    //   autoClose: 5000, // Duration in milliseconds
    //   position: "top-center", // Position on screen
    //   hideProgressBar: true, // Hide progress bar if you want
    //   closeOnClick: true,
    //   pauseOnHover: true,
    // });
  };

  const displayAmazingMessages = () => {
    let amazingMessages = [
      "Amazing!",
      "Astounding!",
      "Wonderful!",
      "Well done!",
      "Good job!",
      "Congratulations!",
      "Fantastic!",
      "Marvelous!",
    ];

    let lastMessage = amazingMessages.length - 1;
    let InitialMessageValue = 0;
    let MessageNumber = parseInt(localStorage.getItem("MessageNumber"));
    if (!MessageNumber) {
      localStorage.setItem("MessageNumber", InitialMessageValue);
      let theNewMessageNumber = 0;
      MessageNumber = parseInt(localStorage.getItem("MessageNumber"));

      if (MessageNumber === lastMessage) {
        theNewMessageNumber = InitialMessageValue;
        localStorage.setItem("MessageNumber", theNewMessageNumber);
      } else {
        theNewMessageNumber = MessageNumber + 1;
        localStorage.setItem("MessageNumber", theNewMessageNumber);
      }

      displayToast(amazingMessages[theNewMessageNumber]);
    } else {
      let theNewMessageNumber = 0;
      if (MessageNumber === lastMessage) {
        theNewMessageNumber = InitialMessageValue;
        localStorage.setItem("MessageNumber", theNewMessageNumber);
      } else {
        theNewMessageNumber = MessageNumber + 1;
        localStorage.setItem("MessageNumber", theNewMessageNumber);
      }
      displayToast(amazingMessages[theNewMessageNumber]);
    }
  };

  //display toast if cards are divisible by 3/ at cards intervals of 3
  useEffect(() => {
    if (!viewedCards.length) {
      return;
    }

    if (viewedCards.length === 1) {
      displayToast("You just started level 1. What an adventure!");
      displayToast(
        "Make the experience as interesting as possible, by playing your favourite music in the background"
      );
    }

    if (viewedCards.length % 3 === 0) {
      displayAmazingMessages();
    }
  }, [viewedCards.length]);

  //Increment game Category as required and assign Gifts
  useEffect(() => {
    if (!viewedCards.length) {
      return;
    }

    if (viewedCards.length === 8) {
      setGameLevel(2);
      setBackgroundPhoto(b2);
      setGifts((gifts) => [...gifts, [endLevel1Gifts, "8"]]);
      endOfLevelAlert(1); //Pass previous Level
    }
    if (viewedCards.length === 16) {
      setGameLevel(3);
      setBackgroundPhoto(b3);
      setGifts((gifts) => [...gifts, [endLevel2Gifts, "16"]]);
      endOfLevelAlert(2); //Pass previous Level
    }
    if (viewedCards.length === 24) {
      setGameLevel(4);
      setBackgroundPhoto(b4);
      setGifts((gifts) => [...gifts, [endLevel3Gifts, "24"]]);
      endOfLevelAlert(3); //Pass previous Level
    }
    if (viewedCards.length === 32) {
      setGameLevel(5);
      setBackgroundPhoto(b5);
      setGifts((gifts) => [...gifts, [endLevel4Gifts, "32"]]);
      endOfLevelAlert(4); //Pass previous Level
    }
    if (viewedCards.length === 40) {
      setGameLevel(6);
      setBackgroundPhoto(b6);
      setGifts((gifts) => [...gifts, [endLevel5Gifts, "40"]]);
      endOfLevelAlert(5); //Pass previous Level
    }
    if (viewedCards.length === 48) {
      setGameLevel(7);
      setBackgroundPhoto(b7);
      setGifts((gifts) => [...gifts, [endLevel6Gifts, "48"]]);
      endOfLevelAlert(6); //Pass previous Level
    }
    if (viewedCards.length === 56) {
      setGameLevel(8);
      setBackgroundPhoto(b8);
      setGifts((gifts) => [...gifts, [endLevel7Gifts, "56"]]);
      endOfLevelAlert(7); //Pass previous Level
    }
    if (viewedCards.length === 64) {
      setGameLevel(9);
      setBackgroundPhoto(b9);
      setGifts((gifts) => [...gifts, [endLevel8Gifts, "64"]]);
      endOfLevelAlert(8); //Pass previous Level
    }
    if (viewedCards.length === 72) {
      setGameLevel(10);
      setBackgroundPhoto(b10);
      setGifts((gifts) => [...gifts, [endLevel9Gifts, "72"]]);
      endOfLevelAlert(9); //Pass previous Level
    }
    if (viewedCards.length === 80) {
      setGameLevel(11);
      setBackgroundPhoto(b11);
      setGifts((gifts) => [...gifts, [endLevel10Gifts, "80"]]);
      endOfLevelAlert(10); //Pass previous Level
    }
    if (viewedCards.length === 88) {
      setGameLevel(12);
      setBackgroundPhoto(b12);
      setGifts((gifts) => [...gifts, [endLevel11Gifts, "88"]]);
      endOfLevelAlert(11); //Pass previous Level
    }
    if (viewedCards.length === 96) {
      setGameLevel(13);
      setBackgroundPhoto(b13);
      setGifts((gifts) => [...gifts, [endLevel12Gifts, "96"]]);
      endOfLevelAlert(12); //Pass previous Level
    }
    if (viewedCards.length === 104) {
      setGameLevel(14);
      setBackgroundPhoto(b14);
      setGifts((gifts) => [...gifts, [endLevel13Gifts, "104"]]);
      endOfLevelAlert(13); //Pass previous Level
    }
    if (viewedCards.length === 112) {
      setGameLevel(15);
      setBackgroundPhoto(b15);
      setGifts((gifts) => [...gifts, [endLevel14Gifts, "104"]]);
      endOfLevelAlert(14); //Pass previous Level
    }
    if (viewedCards.length === cards.length && cards.length !== 0) {
      //Signify End of Level
      setGameLevel(16);
      setBackgroundPhoto(defaultPic);
      setGifts((gifts) => [...gifts, [endLevel15Gifts, "finalGift"]]);
    }
  }, [viewedCards.length]);

  useEffect(() => {
    localStorage.setItem("viewedCards", JSON.stringify(viewedCards));
    setViewedCards(JSON.parse(localStorage.getItem("viewedCards")));
  }, [viewedCards.length]);

  useEffect(() => {
    localStorage.setItem("gifts", JSON.stringify(gifts));
    setGifts(JSON.parse(localStorage.getItem("gifts")));
  }, [gifts.length]);

  useEffect(() => {
    // Scroll to the bottom of the chat container when messages change
    chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
  }, [viewedCards, messageFeedback.length]); // Trigger effect whenever messages change

  useEffect(() => {
    const fetchData = async () => {
      try {
        const requestData = {
          where_: { where_: "deck=" + uid },
          limit_: { limit_: "200" },
        };
        const response = await fetchDataFromAPI("/cards_read.php", requestData);
        const parsedRes = JSON.parse(response);

        parsedRes.details_.forEach((item) => {
          setCards((cards) => [...cards, item.content]);
          setQuestionid((questionid) => [...questionid, item.uid]);
        });
      } catch (error) {
        console.log("error", error);
        // Handle error
      }
    };

    fetchData();
  }, []);

  //Set category Name
  useEffect(() => {
    if (!uid) {
      return;
    }
    if (parseInt(uid) === 3) {
      setGameCategoryName("Engaged 1");
    }
    if (parseInt(uid) === 11) {
      setGameCategoryName("Romance n' Finance");
    }
    if (parseInt(uid) === 14) {
      setGameCategoryName("Between the sheets 1");
    }
    if (parseInt(uid) === 16) {
      setGameCategoryName("Between the Sheets 2");
    }
    if (parseInt(uid) === 17) {
      setGameCategoryName("Engaged 2");
    }
    if (parseInt(uid) === 18) {
      setGameCategoryName("Married");
    }
  }, [uid]);

  useEffect(() => {
    if (
      selectedGameMode !== "online" ||
      categoryChanged === null ||
      !categoryChanged
    ) {
      return;
    }

    clearCategoryData();
    navigate("/PlayGround", {
      state: {
        selectedGameMode: selectedGameMode,
        uid: categoryChanged,
        playerOneGender: playerOneGender,
        playerOneName: playerOneName,
        playerOneAvator: playerOneAvator,
        playerTwoGender: playerTwoGender,
        playerTwoName: playerTwoName,
        playerTwoAvator: playerTwoAvator,
        inviteCode: inviteCode,
        playerNumber: playerNumber,
      },
    });
    refreshPage();
  }, [categoryChanged]);

  //Save Invite code to local storage
  //Also check to see if invite code match or not, to clear viewed cards
  useEffect(() => {
    if (!inviteCode) {
      return;
    }
    let previousInviteCode = localStorage.getItem("inviteCode") || "";

    if (previousInviteCode !== inviteCode) {
      //If it is same, then clear and redirect
      //First set invite code to local storage, for future reference
      localStorage.setItem("inviteCode", inviteCode);
      clearCategoryData();
      navigate("/PlayGround", {
        state: {
          selectedGameMode: selectedGameMode,
          uid: categoryChanged,
          playerOneGender: playerOneGender,
          playerOneName: playerOneName,
          playerOneAvator: playerOneAvator,
          playerTwoGender: playerTwoGender,
          playerTwoName: playerTwoName,
          playerTwoAvator: playerTwoAvator,
          inviteCode: inviteCode,
          playerNumber: playerNumber,
        },
      });
      refreshPage();
    }
  }, [inviteCode]);

  //Add session Expiry, if no more viewed cards len change for 30 minutes
  useEffect(() => {
    // Clear any existing timer
    let timer;

    // Function to handle session expiry
    const handleSessionExpiry = () => {
      handleClearGame();
    };

    // Set a new timer to handle session expiry after 30 minutes (1800000 milliseconds)
    if (viewedCards.length) {
      timer = setTimeout(handleSessionExpiry, 1800000);
    }

    // Cleanup function to clear the timer when viewedCards changes or the component unmounts
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [viewedCards]);

  //Change Game category, after category is done
  const handleChangeGameCategory = async (uid) => {
    if (!uid) {
      return;
    }
    console.log("sENT UID", uid);
    //Clear previous data
    if (selectedGameMode === "online") {
      //Update deck and currentMessageNumber, for other player to see.
      handlePlayClick(uid);
      console.log("Val: ::::", uid);
    }
    clearCategoryData();
    navigate("/PlayGround", {
      state: {
        selectedGameMode: selectedGameMode,
        uid: uid,
        playerOneGender: playerOneGender,
        playerOneName: playerOneName,
        playerOneAvator: playerOneAvator,
        playerTwoGender: playerTwoGender,
        playerTwoName: playerTwoName,
        playerTwoAvator: playerTwoAvator,
        inviteCode: inviteCode,
        playerNumber: playerNumber,
      },
    });

    refreshPage();
  };

  //Set one Card to ViewedCards on Initial Reload
  useEffect(() => {
    if (cards.length > 0 && viewedCards.length === 0) {
      const initialCard = cards[0];
      setViewedCards((viewedCards) => [...viewedCards, initialCard]);
    }
  }, [cards, viewedCards]);

  //Load Messages as soon as a player clicks next
  useEffect(() => {
    if (currentMessageNumber > 0) {
      if (cards.length > 0) {
        let i;
        if (
          viewedCards.length > 0 &&
          currentMessageNumber >= viewedCards.length
        ) {
          for (i = viewedCards.length; i < currentMessageNumber + 1; i++) {
            const initialCard = cards[i];
            setViewedCards((viewedCards) => [...viewedCards, initialCard]);
          }
        }
      }
    }
  }, [currentMessageNumber, cards, viewedCards]);

  //check Player status
  useEffect(() => {
    if (!inviteCode && selectedGameMode !== "online") {
      return;
    }

    const fetchData = async () => {
      try {
        const requestData = {
          where_: {
            where_: "sessionid='" + inviteCode + "'",
          },
          limit_: {
            limit_: "0,1",
          },
        };

        const response = await fetchDataFromAPI(
          "/n_play_status_check.php",
          requestData
        );

        if (response) {
          const details = JSON.parse(response.details_)[0];
          setTurn(details ? parseInt(details.turn) : null);
          setCurrentMessageNumber(details ? parseInt(details.card) : null);
          setMyTurn(details && parseInt(details.turn) === playerNumber);
          console.log("Details UId", details.uid);
          console.log(" Turn", details.turn);
          console.log(" Deck", details.deck);
          console.log(" Current UID", uid);

          if (
            parseInt(details.deck) !== parseInt(uid) &&
            details.deck !== null
          ) {
            //Clear previous data
            setCatgoryChanged(parseInt(details.deck));
          }
        }
      } catch (error) {
        console.log("Error fetching data:", error);
      } finally {
        // Schedule next fetch
        setTimeoutId(setTimeout(fetchData, 200));
      }
    };

    // Initial fetch
    fetchData();

    return () => {
      // Clear timeout on component unmount or dependency change
      clearTimeout(timeoutId);
    };
  }, []);

  //Fetch Messages
  useEffect(() => {
    if (!inviteCode && selectedGameMode !== "online") {
      return;
    }

    const fetchMessages = async () => {
      try {
        const requestData = {
          where_: {
            where_: "sessionid='" + inviteCode + "'",
          },
          limit_: {
            limit_: "10000",
          },
        };

        const response = await fetchDataFromAPI("/chat.php", requestData);
        if (response) {
          const details = JSON.parse(response).details_;

          const messages = [];

          // Loop through each item in details array
          details.forEach((item) => {
            const feedback = item.feedback;
            const question = item.question;
            const commentedPlayer = item.player;
            messages.push([question, feedback, commentedPlayer]);
          });

          setMessageFeedback(messages);
        }
      } catch (error) {
        console.log("Error fetching data:", error);
      } finally {
        // Schedule next fetch
        setTimeoutId(setTimeout(fetchMessages, 200));
      }
    };

    // Initial fetch
    fetchMessages();

    return () => {
      // Clear timeout on component unmount or dependency change
      clearTimeout(timeoutId);
    };
  }, []);

  //send new message
  const sendMessage = async (message) => {
    setIsDisabled(true); // Disable the button
    setTimeout(() => {
      setIsDisabled(false); // Re-enable the button after 5 seconds
    }, 5000);

    if (message !== "" && message != null) {
      const formData = new FormData();
      formData.append("session_id", inviteCode);
      formData.append("player", playerNumber);
      formData.append("deck", uid);
      formData.append("questionid", viewedCards.length);
      formData.append("feedback", message);
      formData.append("its_personal", "For both of you");
      formData.append("question", cards[currentMessageNumber]);

      try {
        const response = await postDataToAPI("/chat_new.php", formData);
        if (response) {
          if (response === 1) {
            setTypedText("");
          }
        }
      } catch (error) {
        console.log("error", error);
        // Handle error
      }
    }
  };

  const handleTextMessageChange = (event) => {
    setTypedText(event.target.value);
  };

  const handleClearGame = () => {
    localStorage.clear("viewedCards");
    localStorage.clear("gifts");
    navigate("/");
  };

  return (
    <div
      style={{
        backgroundImage: `url(${backgroundPhoto})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        height: "100%",
      }}
      className="container"
    >
      <Container>
        <Row style={{ backgroundColor: "whitesmoke", opacity: 0.8 }}>
          <Col sm={12}>
            <Col
              lg={12}
              sm={12}
              style={{
                marginTop: 10,
                justifyContent: "center",
                textAlign: "center",
                height: "100vh",
                border: "1px solid #9A000F",
              }}
            >
              <div>
                <img
                  src={NtarasiIcon}
                  alt="NtarasiIcon"
                  style={{ height: "30px" }}
                  onClick={() => handleClearGame()}
                />
                <p style={{ margin: -5 }}>Category: {gameCategoryName}</p>
              </div>
              <div
                style={{
                  marginTop: 0,
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                {/* Level and Exit game button */}
                <div style={{ flexGrow: 1 }}>
                  <p style={{ fontWeight: "bold" }}>
                    Level: <span style={{ color: "red" }}>{gameLevel}</span>
                  </p>
                </div>
                <div style={{ flexGrow: 1 }}>
                  <p style={{ fontWeight: "bold" }}>Play Ground</p>
                </div>
                <div style={{ flexGrow: 1 }}>
                  <button
                    style={{
                      backgroundColor: "brown",
                      color: "white",
                      borderRadius: 2,
                    }}
                    onClick={() => handleClearGame()}
                  >
                    Exit
                  </button>
                  <button
                    style={{
                      backgroundColor: "green",
                      color: "white",
                      borderRadius: 2,
                    }}
                    onClick={() => refreshPage()}
                  >
                    Refresh
                  </button>
                </div>
              </div>

              {/* Chat container */}
              <div
                ref={chatContainerRef}
                style={{
                  overflowY: "scroll",
                  height: "75vh",
                  borderTop: "1px solid #9A000F",
                  //borderRadius: "5px",
                  padding: "10px",
                }}
              >
                {/* Mapping viewed cards and messages */}
                {viewedCards.map((card, outerIndex) => {
                  let foundMatch = false;
                  let message = [];
                  let theMessageFeedbacks = [];
                  for (
                    let innerIndex = 0;
                    innerIndex < messageFeedback.length;
                    innerIndex++
                  ) {
                    const messageFed = messageFeedback[innerIndex];
                    if (card === messageFed[0]) {
                      foundMatch = true;
                      message = messageFed;
                      theMessageFeedbacks.push([messageFed[1], messageFed[2]]);
                    }
                  }
                  return (
                    <div key={outerIndex}>
                      <div
                        style={{
                          float: "left",
                          background: "#7B3C3C",
                          color: "#ffffff",
                          width: "70%",
                          boxShadow: "2px 5px 2px rgb(0 0 0 / 50%)",
                          border: "2px solid #34a5ff",
                          textShadow: "2px 2px #004175",
                          borderRadius: "13px",
                          padding: "10px 15px",
                          fontSize: "16px",
                          fontWeight: "bold",
                          minHeight: "30px",
                          margin: "14px 15px 10px 5px",
                        }}
                      >
                        <p>{card}</p>
                        {selectedGameMode === "offline" && (
                          <span style={{ color: "pink" }}>
                            Please give a verbal Response.
                          </span>
                        )}
                      </div>
                      {foundMatch ? (
                        <div>
                          {theMessageFeedbacks
                            .slice()
                            .reverse()
                            .map((messageFed, index) => {
                              if (messageFed[1] === "1") {
                                return (
                                  <div
                                    key={index}
                                    style={{
                                      margin: "5px 0px 5px 0px",
                                      float: "left",
                                      border: "2px solid #d2d2d2",
                                      padding: "7px",
                                      width: "70%",
                                      borderRadius: 10,
                                      backgroundColor: "#FFDEAD",
                                      //fontFamily: "Indie Flower, cursive",
                                      //fontStyle: "italic",
                                    }}
                                  >
                                    <div
                                      style={{
                                        background: "white",
                                        width: "40px",
                                        borderRadius: 20,
                                      }}
                                    >
                                      <img
                                        style={{ height: "40px" }}
                                        src={playerOneAvator}
                                        alt="profile1"
                                      />
                                    </div>
                                    <div style={{ textAlign: "center" }}>
                                      {messageFed[0]}
                                    </div>
                                  </div>
                                );
                              } else if (messageFed[1] === "2") {
                                return (
                                  <div
                                    key={index}
                                    style={{
                                      margin: "5px 0px 5px 0px",
                                      float: "right",
                                      border: "2px solid #d2d2d2",
                                      padding: "7px",
                                      width: "70%",
                                      borderRadius: 10,
                                      backgroundColor: "#F8F8FF",
                                      // fontFamily: "Indie Flower, cursive",
                                      // fontStyle: "italic",
                                    }}
                                  >
                                    <div
                                      style={{
                                        background: "grey",
                                        width: "40px",
                                        borderRadius: 20,
                                      }}
                                    >
                                      <img
                                        style={{
                                          height: "40px",
                                          justifyContent: "center",
                                        }}
                                        src={playerTwoAvator}
                                        alt="profile2"
                                      />
                                    </div>
                                    <div style={{ textAlign: "center" }}>
                                      {messageFed[0]}
                                    </div>
                                  </div>
                                );
                              } else {
                                return null;
                              }
                            })}
                        </div>
                      ) : (
                        <p style={{ marginLeft: "10px" }}></p>
                      )}
                      {/* Gifts */}
                      {gifts.map((gift, giftIndex) => {
                        if (
                          parseInt(gift[1]) === outerIndex &&
                          parseInt(gift[1]) !== cards.length
                        ) {
                          return (
                            <div
                              style={{
                                margin: "5px 0px 5px 0px",
                                float: "left",
                                border: "2px solid #d2d2d2",
                                textAlign: "left",
                                padding: "7px",
                                width: "80%",
                                borderRadius: 10,
                                backgroundColor: "pink",
                              }}
                            >
                              <h5>
                                List of love gifts(Pick one to be given in the
                                next 3 days)
                              </h5>
                              <p>{gift[0][0]}</p>
                              <p>{gift[0][1]}</p>
                              <p>{gift[0][2]}</p>
                              <p>{gift[0][3]}</p>
                            </div>
                          );
                        }
                        if (
                          gift[1] === "finalGift" &&
                          outerIndex + 1 === viewedCards.length
                        ) {
                          return (
                            <div
                              style={{
                                margin: "5px 0px 5px 0px",
                                float: "left",
                                border: "2px solid #d2d2d2",
                                textAlign: "left",
                                padding: "7px",
                                width: "80%",
                                borderRadius: 10,
                                backgroundColor: "pink",
                              }}
                            >
                              <h5>
                                List of love gifts(Pick one to be given in the
                                next 3 days)
                              </h5>
                              <p>{gift[0][0]}</p>
                              <p>{gift[0][1]}</p>
                              <p>{gift[0][2]}</p>
                              <p>{gift[0][3]}</p>
                              <h4>
                                Congratulations! You have completed this
                                category of cards. Choose another category to
                                continue enhancing your relationship by clicking
                                one of the buttons below.
                              </h4>
                              <ul>
                                <li>
                                  <button
                                    style={{ height: "30px" }}
                                    onClick={() => {
                                      handleChangeGameCategory(3);
                                    }}
                                  >
                                    Engaged 1
                                  </button>
                                </li>
                                <br />
                                <li>
                                  <button
                                    style={{ height: "30px" }}
                                    onClick={() => {
                                      handleChangeGameCategory(11);
                                    }}
                                  >
                                    Romance and Finance
                                  </button>
                                </li>
                                <br />
                                <li>
                                  <button
                                    style={{ height: "30px" }}
                                    onClick={() => {
                                      handleChangeGameCategory(14);
                                    }}
                                  >
                                    Between the sheets 1
                                  </button>
                                </li>
                                <br />
                                <li>
                                  <button
                                    style={{ height: "30px" }}
                                    onClick={() => {
                                      handleChangeGameCategory(16);
                                    }}
                                  >
                                    Between the Sheets 2
                                  </button>
                                </li>
                                <br />
                                <li>
                                  <button
                                    style={{ height: "30px" }}
                                    onClick={() => {
                                      handleChangeGameCategory(17);
                                    }}
                                  >
                                    Engaged 2
                                  </button>
                                </li>
                                <br />
                                <li>
                                  <button
                                    style={{ height: "30px" }}
                                    onClick={() => {
                                      handleChangeGameCategory(18);
                                    }}
                                  >
                                    Married
                                  </button>
                                </li>
                              </ul>
                            </div>
                          );
                        }
                        return null;
                      })}
                    </div>
                  );
                })}
              </div>
              <div style={{ height: "30px" }}>
                <ToastContainer />
              </div>
              {/* Input and send button for online mode */}
              {selectedGameMode === "online" && (
                <div style={{ marginTop: "20px", marginBottom: "10px" }}>
                  <input
                    style={{ width: "70%", height: "30px", borderColor: "green", color: "green" }}
                    type="text"
                    value={typedText}
                    placeholder="Type Reply..."
                    onChange={handleTextMessageChange}
                  />
                  <button
                    style={{ height: "30px", marginLeft: "10px", borderColor: "green", color: "green" }}
                    onClick={() => sendMessage(typedText)}
                    disabled={isDisabled}
                  >
                    Send
                  </button>
                </div>
              )}
              <div
                className="row"
                style={{
                  marginTop: 20,
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexWrap: "wrap",
                 
                }}
              >
                {/* Player 1 Info */}
                <div className="col-sm-4"
                  style={{
                    display: "flex",
                    justifyContent: "center", // Center content horizontally
                    marginBottom: 20, // Space between rows on small screens
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "whitesmoke",
                      padding: 10,
                      borderRadius: 10,
                      //width: "100%",
                      maxWidth: "300px",
                      textAlign: "center", // Center content inside the box
                    }}
                  >
                    <div>
                      <span style={{ color: "#DC3545" }}>Player 1:</span>
                      <span style={{ color: "#7E1A8E", fontSize: "16px" }}>
                        {playerOneName}
                      </span>
                    </div>
                    <img
                      style={{ height: "40px", marginTop: 10 }}
                      src={playerOneAvator}
                      alt="profile1"
                    />
                  </div>
                </div>

                {/* Play button or Wait message */}
                <div className="col-sm-4"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: 20, // Adds spacing for small screens
                  }}
                >
                  {selectedGameMode === "online" && myTurn && (
                    <button
                      style={{
                        height: 60,
                        marginTop: 20,
                        backgroundColor: "rgb(81, 180, 8)",
                        borderRadius: 10,
                        width: 100,
                      }}
                      onClick={() => handlePlayClick(null)}
                    >
                      Next Card
                    </button>
                  )}
                  {selectedGameMode === "online" && !myTurn && (
                    <p style={{ color: "red", marginTop: 20 }}>
                      Wait for your turn
                    </p>
                  )}
                  {selectedGameMode === "offline" && (
                    <button
                      style={{
                        height: 60,
                        marginTop: 20,
                        backgroundColor: "rgb(81, 180, 8)",
                        borderRadius: 10,
                        width: 100,
                      }}
                      onClick={() => handlePlayClick(null)}
                    >
                      Next Card
                    </button>
                  )}
                </div>

                {/* Player 2 Info */}
                <div className="col-sm-4"
                  style={{
                    display: "flex",
                    justifyContent: "center", // Center content horizontally
                    marginBottom: 20,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "whitesmoke",
                      padding: 10,
                      borderRadius: 10,
                      //width: "100%",
                      maxWidth: "300px",
                      textAlign: "center",
                    }}
                  >
                    <div>
                      <span style={{ color: "#DC3545" }}>Player 2:</span>
                      <span style={{ color: "#7E1A8E", fontSize: "16px" }}>
                        {playerTwoName}
                      </span>
                    </div>
                    <img
                      style={{ height: "40px", marginTop: 10 }}
                      src={playerTwoAvator}
                      alt="profile2"
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default PlayGround;
